import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import {
  type DtoUpsertTrainingProfileRequest,
  EnumsTrainingBlockType,
  type ModelsBlockPromptConfig,
  type ModelsPromptConfig,
  type ModelsTrainingProfile,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { Loading } from '../Loading';
import { PromptTemplatePicker } from '../PromptTemplate/PromptTemplatePicker';

type FormData = DtoUpsertTrainingProfileRequest;

function defaultBlocks(curr: ModelsBlockPromptConfig[] | null | undefined) {
  const blocks = curr || [];
  for (const type of Object.values(EnumsTrainingBlockType)) {
    if (!blocks.find((b) => b.type === type)) {
      blocks.push({ type, templateId: '' });
    }
  }
  blocks.sort((a, b) => a.type.localeCompare(b.type));
  return blocks;
}

function PromptTemplateField(props: {
  label: string;
  disabled?: boolean;
  value: ModelsPromptConfig | null | undefined;
  onChange: (
    value: { templateId: string; templateName: string } | null
  ) => void;
  error?: unknown;
}) {
  return (
    <div>
      <p
        className={`text-sms mb-1 font-bold ${
          props.error ? 'text-red-002' : 'text-white'
        }`}
      >
        {props.label}
      </p>
      <PromptTemplatePicker
        disabled={props.disabled}
        templateId={props.value?.templateId}
        onChange={(value) => {
          props.onChange(
            value
              ? {
                  templateId: value.id,
                  templateName: value.name,
                }
              : null
          );
        }}
        isClearable
      />
    </div>
  );
}

export function TrainingProfileEditor(props: {
  profile: Nullable<ModelsTrainingProfile>;
  onSave: (p: ModelsTrainingProfile) => void;
  onCancel: () => void;
}): JSX.Element {
  const { profile } = props;
  const form = useForm<FormData>({
    defaultValues: {
      ...profile,
      blocks: defaultBlocks(profile?.blocks),
    },
  });
  const {
    register,
    handleSubmit,
    control,
    setError,
    reset: resetForm,
    formState: { isSubmitting, errors, isDirty },
  } = form;

  const { fields: blocks } = useFieldArray<FormData, 'blocks', 'type'>({
    control,
    name: 'blocks',
  });

  const onSubmit = handleSubmit(async (data: FormData) => {
    try {
      if (profile) {
        const resp = await apiService.trainingProfile.updateProfile(
          profile.id,
          data
        );
        props.onSave(resp.data.profile);
      } else {
        const resp = await apiService.trainingProfile.createProfile(data);
        props.onSave(resp.data.profile);
      }
    } catch (error) {
      setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
      throw error;
    }
  });

  return (
    <FormProvider {...form}>
      <form className='flex flex-col gap-4 text-white' onSubmit={onSubmit}>
        <h1 className='text-2xl font-bold'>
          {profile?.id ? `Edit Profile: ${profile.name}` : 'Create Profile'}
        </h1>
        <div className='flex gap-4'>
          <div className='w-100 flex flex-col gap-4'>
            <div>
              <p
                className={`text-sms mb-1 font-bold ${
                  errors.name ? 'text-red-002' : 'text-white'
                }`}
              >
                Name:
              </p>
              <input
                {...register('name', { required: true })}
                disabled={profile?.locked}
                className='field mb-0'
              />
            </div>
            <Controller
              control={control}
              name='moduleOutline'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <PromptTemplateField
                  label='Module Outline:'
                  value={field.value}
                  error={fieldState.error}
                  onChange={field.onChange}
                  disabled={profile?.locked}
                />
              )}
            />
            <Controller
              control={control}
              name='topicOutline'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <PromptTemplateField
                  label='Topic Outline:'
                  value={field.value}
                  error={fieldState.error}
                  onChange={field.onChange}
                  disabled={profile?.locked}
                />
              )}
            />
            <Controller
              control={control}
              name='topicBlockOverview'
              render={({ field, fieldState }) => (
                <PromptTemplateField
                  label='Topic Block Overview:'
                  value={field.value}
                  error={fieldState.error}
                  onChange={field.onChange}
                  disabled={profile?.locked}
                />
              )}
            />
            <Controller
              control={control}
              name='blockDialogue'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <PromptTemplateField
                  label='Block Dialogue:'
                  value={field.value}
                  error={fieldState.error}
                  onChange={field.onChange}
                  disabled={profile?.locked}
                />
              )}
            />
            <Controller
              control={control}
              name='blockBgMusic'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <PromptTemplateField
                  label='Block Background Music:'
                  value={field.value}
                  error={fieldState.error}
                  onChange={field.onChange}
                  disabled={profile?.locked}
                />
              )}
            />
            {blocks.map((block, index) => (
              <Controller
                key={index}
                control={control}
                name={`blocks.${index}`}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <PromptTemplateField
                    label={`Block - ${block.type}:`}
                    value={field.value}
                    error={fieldState.error}
                    disabled={profile?.locked}
                    onChange={(val) => {
                      field.onChange(
                        val
                          ? {
                              type: block.type,
                              templateId: val.templateId,
                              templateName: val.templateName,
                            }
                          : null
                      );
                    }}
                  />
                )}
              />
            ))}
            {errors.root?.serverError && (
              <div className='text-sms text-red-002'>
                {errors.root.serverError.message}
              </div>
            )}
            {!profile?.locked ? (
              <div className='flex items-center justify-start gap-2'>
                <button
                  className='btn-secondary w-40 h-10 flex items-center justify-center'
                  onClick={() => {
                    resetForm();
                    props.onCancel();
                  }}
                  disabled={isSubmitting}
                  type='button'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='btn-primary w-40 h-10 flex items-center justify-center'
                  disabled={isSubmitting || !isDirty}
                >
                  {isSubmitting ? <Loading text='' /> : 'Save'}
                </button>
              </div>
            ) : (
              <div className='text-tertiary'>
                You need to unlock this profile before editing
              </div>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
